<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Pendaftar atau Perjanjian Akan Datang</b> {{ clinic_name }}
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/appointment/add')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :isUpcoming="true"
              :items="items"
              :itemExcel="itemExcel"
              :itemPopUp="itemPopUp"
              :fields="fields"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              @appointmentOnStatusChange="pagination"
              @filter="generalFilter"
              @filterByPatient="generalFilter"
              @btnDeleteOnClick="btnDeleteOnClick"
              @pageOnClick="pageOnClick"
              @btnExportOnClick="btnExportOnClick"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/appointments/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";

export default {
  components: {
    Card,
    Table,
  },

  data() {
    return {
      filter: {
        upcoming: "true",
        appointment_status_id: "",
        doctor_id: "",
        patient_name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("month").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      itemExcel: [],
      itemPopUp: [],
      manipulateBtn: false,
      currentUser: getUser(),
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let route;
      if (this.currentUser.user_type_id == 1)
        route = `appointments/get-by-doctor/${this.currentUser.id}`;
      else route = "appointments";
      let filterParams = `&upcoming=${this.filter.upcoming}&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async getExport() {
      const excelRoute = "appointment-excel";
      const popUpRoute = "appointment-no-paginations";
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let responseExcel = await module.get(
        excelRoute,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let responsePopUp = await module.get(
        popUpRoute,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.itemExcel = responseExcel;
      this.itemPopUp = responsePopUp;
    },

    setFilter(sent) {
      for (const key in this.filter) {
        this.filter[key] = sent[key];
      }
      if (
        this.filter.appointment_status_id != "" ||
        this.filter.doctor_id != "" ||
        this.filter.start_date != "" ||
        this.filter.end_date != ""
      )
        this.currentPage = 1;
        this.filter.upcoming = "true";

        console.log('filter', this.filter)
        console.log('sent', sent)
    },

    async generalFilter(sent) {
      this.setFilter(sent);
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      let result = await module.delete("appointments/" + id);
      if (result) this.pagination();
    },

    async btnExportOnClick(filter) {
      this.setFilter(filter);
      await this.getExport();
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "5002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pendaftaran atau Perjanjian" },
      { title: "Pendaftaran atau Perjanjian Akan Datang" },
    ]);
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
